import { createRoot } from 'react-dom';

import './child/script';
import './style.scss';
import Style from './Components/Common/Style';

document.addEventListener('DOMContentLoaded', () => {
	const servicesEls = document.querySelectorAll('.wp-block-services-section-services');
	servicesEls.forEach(servicesEl => {
		const attributes = JSON.parse(servicesEl.dataset.attributes);

		const ssbServicesStyle = document.querySelector(`#${servicesEl.id} .ssbServicesStyle`);

		createRoot(ssbServicesStyle).render(<Style attributes={attributes} clientId={attributes.cId} />);

		servicesEl?.removeAttribute('data-attributes');
	});
});